import axios from "axios"
import isJson from "is-json"
import isJSON from "is-json"

import { useRouter } from "next/router"
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { toast } from "react-toastify"

import useFirebase from "@/lib/firebase"

interface IAuthContext {
  isAuthenticated: boolean
  user: IUser | null
  // eslint-disable-next-line no-unused-vars
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  token: string | null
  loading: boolean
  userEmail: string | null
  setRedirectTo: Dispatch<SetStateAction<string | undefined>>
  shouldUpgradeMembership: boolean
  closeUpgradeMembershipDialog: () => Promise<void>
  classesReserved: {
    id: string
    bookingid: number
    seat: number
    bookingparentid: number
    bookedBy: number
    bookedFor: string
    classStartDate: string
    classStartTime: string
  }[]
  shouldPurchaseMembership: boolean
  closePurchaseMembershipDialog: () => Promise<void>
}

export interface IUser {
  uid: string
  id: string
  email: string
  phone: any
  firstname: string
  gid: string
  status: string
  xenditId: string
  surname: string
  passwordAuthenticated?: boolean
  memberships: {
    membershiptypeid: number
    name: string
    startdate: string
    enddate: string
    firstpaymentdate: any
    id: number
    price: string
    visitsused: number
    visitlimit: any
    onlinerenew: boolean
    master_member_name: any
    onhold: boolean
    nextpaymentdate: any
    expire_soon: boolean
    can_renew: boolean
    incomplete: boolean
    can_share: boolean
    can_cancel: boolean
    cancellation_fee: any
    earliest_cancellation_date: string
    earliest_hold_date: string
    upcoming_hold_exists: boolean
    startdate_str: string
    enddate_str: string
    firstpaymentdate_str: any
    nextpaymentdate_str: any
    cancellation_next_bill_date: string
    cancellation_next_bill_date_str: string
    contract_url: string
    next_hold_startdate: any
  }[]
}

export interface Membership {
  membershiptypeid: number
  name: string
  startdate: string
  enddate: string
  firstpaymentdate: any
  id: number
  price: string
  visitsused: number
  visitlimit: any
  onlinerenew: boolean
  master_member_name: any
  onhold: boolean
  nextpaymentdate: any
  expire_soon: boolean
  can_renew: boolean
  incomplete: boolean
  can_share: boolean
  can_cancel: boolean
  cancellation_fee: any
  earliest_cancellation_date: string
  earliest_hold_date: string
  upcoming_hold_exists: boolean
  startdate_str: string
  enddate_str: string
  firstpaymentdate_str: any
  nextpaymentdate_str: any
  cancellation_next_bill_date: string
  cancellation_next_bill_date_str: string
  contract_url: string
  next_hold_startdate: any
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  user: null,
  async login() {},
  logout() {},
  token: null,
  loading: true,
  userEmail: null,
  setRedirectTo() {},
  shouldUpgradeMembership: false,
  async closeUpgradeMembershipDialog() {},
  classesReserved: [],
  shouldPurchaseMembership: false,
  async closePurchaseMembershipDialog() {},
})

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { auth } = useFirebase()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<IUser | null>(null)
  const [gmmToken, setGmmToken] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const router = useRouter()
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined)
  const [shouldUpgradeMembership, setShouldUpgradeMembership] = useState(false)
  const [classesReserved, setClassesReserved] = useState<
    {
      id: string
      bookingid: number
      seat: number
      bookingparentid: number
      bookedBy: number
      bookedFor: string
      classStartDate: string
      classStartTime: string
    }[]
  >([])
  const [shouldPurchaseMembership, setShouldPurchaseMembership] =
    useState(false)

  const listenToAuthChange = async () => {
    const { onAuthStateChanged } = await import("firebase/auth")
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user)
        setLoading(true)
        setUserEmail(user.email)
        console.log("calling getMemberProfile")
        axios
          .get<IUser | { message: string }>(
            `/api/getMemberProfile?email=${user.email}`,
          )
          .then((res) => {
            console.log(res.data)
            if ("message" in res.data || !res.data.gid) {
              setLoading(false)
              setIsAuthenticated(true)
              if (router.pathname === "/signup") return
              if (redirectTo && !redirectTo.includes(router.pathname)) {
                router.push(redirectTo)
              }
              if (router.query.signup) {
                return
              }
              if (router.pathname !== "/signup" && !Boolean(redirectTo)) {
                console.log("redirecting to signup")
                router.push("/signup")
              }
              return
            }
            if (
              !Boolean(res.data.passwordAuthenticated) &&
              router.pathname !== "/login/password"
            ) {
              router.push(`/login/password`)
            }
            setUser(res.data)
            setLoading(false)
            setIsAuthenticated(true)
          })
          .catch(async (err) => {
            console.log(err)
            toast.error(
              err?.message || err || "Something went wrong while logging in",
            )
            const { signOut } = await import("firebase/auth")
            signOut(auth)
            setLoading(false)
            setIsAuthenticated(false)
          })
      } else {
        setUser(null)
        setUserEmail(null)
        setIsAuthenticated(false)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (!auth) return

    let unsub: () => void

    listenToAuthChange().then((unsubFunc) => {
      unsub = unsubFunc
    })

    return () => {
      unsub()
    }
  }, [router, redirectTo, auth])

  useEffect(() => {
    if (!user) return
    console.log(user)
    axios
      .post("/api/bookReservedClasses", {
        memberid: user.gid,
        email: user.email,
        uid: user.id,
      })
      .then((res) => {
        const { result } = res.data
        if (result.length > 0) {
          toast.info(
            "You have been booked to the following classes: " +
              result.join(", "),
          )
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.data

        if (errorMessage && !isJson(errorMessage)) {
          toast.error(err.response.data)
        } else if (errorMessage && isJSON(errorMessage)) {
          const { message, bookings } = JSON.parse(errorMessage)
          toast.error("Failed to book classes: " + message)
          if (message === "Ineligible to book") {
            setShouldUpgradeMembership(true)
            setClassesReserved(bookings)
            // setClassesReserved
          } else if (message === "No membership") {
            setShouldPurchaseMembership(true)
            setClassesReserved(bookings)
          }
        }
      })
  }, [user])

  const closeUpgradeMembershipDialog = useCallback(async () => {
    try {
      await axios.post("/api/cancelBookings", {
        memberid: user?.gid,
        email: user?.email,
        uid: user?.id,
      })
    } catch (error) {
      toast.error("Failed to cancel reservations. Please contact support")
    }
    setShouldUpgradeMembership(false)
    setShouldPurchaseMembership(false)
  }, [user])

  const closePurchaseMembershipDialog = useCallback(async () => {
    try {
      await axios.post("/api/cancelBookings", {
        memberid: user?.gid,
        email: user?.email,
        uid: user?.id,
      })
    } catch (error) {
      toast.error("Failed to cancel reservations. Please contact support")
    }
    setShouldPurchaseMembership(false)
  }, [user])

  const login = async (email: string, password: string) => {
    const {
      data: { token, ...rest },
    } = await axios.post<IUser & { token: string }>("/api/login", {
      email,
      password,
    })
    localStorage.setItem("gmmToken", token)
    setUser(rest)
    setIsAuthenticated(true)
    setGmmToken(token)
  }

  useEffect(() => {
    if (localStorage.getItem("gmmToken")) {
      const token = localStorage.getItem("gmmToken")
      setGmmToken(token)

      axios
        .post<IUser & { token: string }>(`/api/loginWithToken`, {
          token,
        })
        .then((res) => {
          const { ...rest } = res.data
          setUser(rest)
          console.log(rest)
          setIsAuthenticated(true)
        })
        .catch((err) => {
          toast.error(
            err?.message || err || "Something went wrong while logging in",
          )
          window.localStorage.removeItem("gmmToken")
          setIsAuthenticated(false)
          setGmmToken(null)
          setUser(null)
        })
    }
  }, [])

  const logout = () => {
    window.localStorage.removeItem("gmmToken")
    setIsAuthenticated(false)
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        token: gmmToken,
        loading,
        userEmail,
        setRedirectTo,
        shouldUpgradeMembership,
        closeUpgradeMembershipDialog,
        classesReserved,
        shouldPurchaseMembership,
        closePurchaseMembershipDialog,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
