import { ApolloError, useQuery } from "@apollo/client"

import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react"

import getProducts from "@/lib/shopify/getProducts.query"

interface IProductContext {
  products: {
    id: string
    title: string
    tags: string[]
    variantId: string
    amount: string
  }[]
  loading: boolean
  error: ApolloError | undefined
  // eslint-disable-next-line no-unused-vars
  setFetchData: (value: boolean) => void
}

const ProductsContext = createContext<IProductContext>({
  products: [],
  loading: false,
  error: undefined,
  setFetchData() {},
})

interface QueryReturnValue {
  products: {
    nodes: {
      id: string
      title: string
      tags: string[]
      variants: {
        nodes: {
          id: string
        }[]
      }
      priceRange: {
        maxVariantPrice: {
          amount: string
        }
      }
    }[]
  }
}

export const ProductsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [products, setProducts] = useState<IProductContext["products"]>([])
  const [fetchData, setFetchData] = useState(false)
  const { loading, error } = useQuery<QueryReturnValue>(getProducts, {
    skip: !fetchData,
    onCompleted(data) {
      const serializedProducts: IProductContext["products"] =
        data.products.nodes.map((product) => ({
          title: product.title,
          id: product.id,
          tags: product.tags,
          variantId: product.variants.nodes[0].id,
          amount: product.priceRange.maxVariantPrice.amount,
        }))
      console.log(serializedProducts)
      setProducts(() => serializedProducts)
    },
  })

  return (
    <ProductsContext.Provider
      value={{
        products,
        loading,
        error,
        setFetchData,
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

export const useProducts = () => {
  return useContext(ProductsContext)
}
